/*
  Below listed global variables have to be used or the tooling will complain.
  Either leave the console.log lines as is, or remove the variable from below eslint "globals" line
  to prevent the "no-unused-vars" eslint error from triggering.
*/

function navigationActive(){
  $('header ul li').removeClass('active');
  if(window.location.href.includes('work')){
    $('header ul li:nth-child(2)').addClass('active');
  }else if(window.location.href.includes('agency')){
    $('header ul li:nth-child(3)').addClass('active');
  }else if(window.location.href.includes('contact')){
    $('header ul li:nth-child(4)').addClass('active');
  }else{
    $('header ul li:nth-child(1)').addClass('active');
  }
}

jQuery(($) => {
  navigationActive();

  $('.logo-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows:true,
    prevArrow:'<span class="slick-prev"><img src="/assets/img/icon-arrow-left.png"></span>',
    nextArrow:'<span class="slick-next"><img src="/assets/img/icon-arrow-right.png"></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          false: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows:false,
          dots:true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false,
          dots: true
        }
      }
    ]
  }) 

  $('.project-filter .badge').click((e) => {
    const key = $(e.target).attr('data-key');
    
    $.each($('.projects div'), (index, value) => {  
      
      if($(value).attr('data-key').indexOf(key) > -1 || key === 'all'){
        $(value).fadeIn();
      }else{
        $(value).fadeOut();
      }
    });
    // eslint-disable-next-line no-undef
    AOS.refresh()
            
  });

  $('.toggle-btn').click(()=> {
    // eslint-disable-next-line no-alert
    $('.toggle-btn').toggleClass('active');
    $('.toggle-btn').parent().toggleClass('active');
  });
});


